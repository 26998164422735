.active {
  height: 48px;
  width: 94%;
  margin: 0 auto;
  padding-left: 0;
  border-radius: 8px;
  &:hover {
    background: #e5e5e5;
    p {
      color: #2a99ff;
    }
  }
  p {
    color: #4c4c4c;
    font-weight: 600;
  }
}
