.BasicTips {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 2px;
  z-index: 99999;
  color: #fff;
  background: #07273d;
  padding: '10px 50px';
  border-radius: '8px';
}

.AdvancedTips {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 2px;
  z-index: 99999;
  color: #fff;
  background: #07273d;
  padding: 10px 50px;
  border-radius: 8px;
}

@media (max-width: 780px) {
  .BasicTips {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 2px;
    z-index: 99999;
    color: #fff;
    background: #07273d;
    padding: '4px 4px';
    border-radius: '8px';
    font-size: 14px;
  }

  .AdvancedTips {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 2px;
    z-index: 99999;
    color: #fff;
    background: #07273d;
    padding: 4px 4px;
    border-radius: 8px;
    font-size: 14px;
  }
}
