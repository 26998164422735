.tips {
  text-align: center;
  margin-top: 10px;
  background: #fffbe6;
  border: 1px solid #ffe58f;
  padding: 8px 12px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  .icon {
    color: red;
    font-size: 24px;
  }
  .tipsText {
    margin-left: 6px;
    font-size: 18px;
  }
}

.formBox {
  .h1 {
    margin-top: 20px;
    text-align: center;
    font-size: 20px;
  }
  .pText {
    text-align: center;
    margin-top: 10px;
  }
  .apple {
    width: 360px;
    height: 40px;
    color: #494949;
    padding: 0 15px;
    font-size: 18px;
    border: 0px;
    background: #f7f7f9;
    border-radius: 10px;
    outline: none;
  }
  .password {
    margin-top: 10px;
    width: 360px;
    height: 40px;
    color: #494949;
    padding: 0 15px;
    font-size: 18px;
    border: 0px;
    background: #f7f7f9;
    border-radius: 10px;
    outline: none;
  }
  .robot_code {
    margin-top: 10px;
    width: 360px;
    height: 40px;
    display: flex;
    input {
      background: #f7f7f9;
      border-radius: 10px;
      outline: none;
      border: 0px;
      padding: 0 15px;
      font-size: 18px;
    }
  }
  .connectBox {
    margin-top: 20px;
    .connect {
      width: 350px;
      height: 40px;
      background: #1677ff;
      color: #fff;
      font-size: 20px;
      font-weight: 600;
      border-radius: 16px;
    }
  }
}

@media (max-width: 780px) {
  .tips {
    text-align: center;
    margin-top: 10px;
    background: #fffbe6;
    border: 1px solid #ffe58f;
    padding: 8px 12px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    .icon {
      color: red;
      font-size: 36px;
    }
    .tipsText {
      margin-left: 6px;
      font-size: 14px;
    }
  }
  .formBox {
    .h1 {
      margin-top: 10px;
      text-align: center;
      font-size: 14px;
    }
    .pText {
      text-align: center;
      margin-top: 10px;
    }
    .apple {
      width: 100%;
      height: 40px;
      color: #494949;
      padding: 0 15px;
      font-size: 18px;
      border: 0px;
      background: #f7f7f9;
      border-radius: 10px;
      outline: none;
    }
    .password {
      margin-top: 10px;
      width: 100%;
      height: 40px;
      color: #494949;
      padding: 0 15px;
      font-size: 18px;
      border: 0px;
      background: #f7f7f9;
      border-radius: 10px;
      outline: none;
    }
    .robot_code {
      width: 100%;
    }
    .connectBox {
      margin-top: 20px;
      width: 100%;
      .connect {
        width: 100%;
        height: 40px;
        background: #1677ff;
        color: #fff;
        font-size: 20px;
        font-weight: 600;
        border-radius: 16px;
      }
    }
  }
}
